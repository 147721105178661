import React from "react"

import SEO from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

import Share from "../../features/Share"

const IndexPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="alvyApp - Share" />
      <Share />
    </PageWrapper>
  )
}

export default IndexPage
