import React from "react"

import { Heading1, Heading2, Link } from "../../components/common"
import AppStoreDownload from "../../components/AppStoreDownload"

const Share = () => {
  return (
    <div>
      <AppStoreDownload />
    </div>
  )
}

export default Share
